import { useNavbarDashboard } from "@/hooks/amplitude/useNavbarDashboard";
import { faBell, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { HeaderTextLink } from "../HeaderTextLink";
import logo from "@/images/logo-suma-azul.webp";
import useUserProfile from "@/hooks/useUserProfile";
import LanguageSelector from "@/components/molecules/LanguageSelector";
import { useFormContext } from "@/context/FormContext";
import Typography from "@/components/atoms/@next/Typography";
import { auth, database } from "@/services/firebase";
import { onValue, ref } from "firebase/database";
import { useFirebaseContext } from "@/context/FirebaseContext";
import { signInWithCustomToken } from "firebase/auth";
import useSumaversityNumberNotification from "@/hooks/sumaversity/notification/useSumaversityNumberNotification";

interface SumaversiryHeaderProps {}

const SumaversiryHeader: React.FC<SumaversiryHeaderProps> = () => {
  const router = useRouter();
  const { useNavbarDashboardTrack } = useNavbarDashboard();
  const isNotification = router.pathname.includes("notification");
  const { handlePrevQuestion, step } = useFormContext();
  const [backHandler, setHandleOnBack] = useState<() => void>(
    () => router.back,
  );
  const { userProfile } = useUserProfile();
  const [numberNotification, setNumberNotification] = useState<number>(() =>
    Number(localStorage.getItem("unreadNotifications") || 0),
  );
  const [firebaseContext, setFirebaseContext] = useFirebaseContext();
  const { numberNotificationAPi } = useSumaversityNumberNotification();

  useEffect(() => {
    if (!firebaseContext.isLoggedIn && userProfile?.firebaseToken) {
      signInWithCustomToken(auth, userProfile?.firebaseToken).then((res) => {
        setFirebaseContext({
          isLoggedIn: true,
          data: res.user.metadata,
        });
      });
    }
  }, [firebaseContext, userProfile?.firebaseToken, setFirebaseContext]);

  useEffect(() => {
    let isMounted = true;
    if (firebaseContext.isLoggedIn) {
      const userRef = ref(database, `users/${userProfile?.id}`);
      const unsubscribe = onValue(userRef, (snapshot) => {
        if (isMounted) {
          const data = snapshot.val();
          if (data && typeof data.unread_notifications_count === "number") {
            const notificationCount = data.unread_notifications_count ?? 0;
            setNumberNotification(numberNotificationAPi);
            setNumberNotification(notificationCount);
            localStorage.setItem(
              "unreadNotifications",
              notificationCount.toString(),
            );
          }
        }
      });
      return () => {
        isMounted = false;
        unsubscribe();
      };
    }
  }, [userProfile?.id, firebaseContext.isLoggedIn, numberNotificationAPi]);

  const handleGoNotification = () => {
    router.push("/sumaversity/notification");
  };

  const onClickHomePage = () => {
    router.push("/sumaversity");
  };

  useEffect(() => {
    // if (handlePrevQuestion && userProfile?.has_initial_questions === false) {
    //   setHandleOnBack(() => handlePrevQuestion);
    // } else if (userProfile?.has_initial_questions) {}
    if (router.pathname.includes("/sumaversity")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (router.pathname.includes("/sumaversity/courses/my-learning")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (router.pathname.includes("/sumaversity/courses/all")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (router.route.includes("/sumaversity/courses/[id]") && router.query.id) {
      setHandleOnBack(
        () => () => router.push("/sumaversity/courses/my-learning"),
      );
    }
    if (
      router.route.includes("/sumaversity/courses/[id]/[idVideo]") &&
      router.query.id &&
      router.query.idVideo
    ) {
      setHandleOnBack(
        () => () => router.push(`/sumaversity/courses/${router.query.id}`),
      );
    }
    if (router.pathname.includes("/sumaversity/GamificationSystem/coins")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (router.pathname.includes("/sumaversity/GamificationSystem/medal")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (router.pathname.includes("/sumaversity/GamificationSystem/trophy")) {
      setHandleOnBack(() => () => router.push("/sumaversity"));
    }
    if (
      (router.pathname.includes("/sumaversity/notification/[commentId]") &&
        router.query.commentId) ||
      (router.query.materialId && router.query.courseId)
    ) {
      setHandleOnBack(() => () => {
        router.push("/sumaversity/notification").then(() => {
          window.location.reload();
        });
      });
    }
  }, [router, userProfile?.has_initial_questions, handlePrevQuestion, step]);

  const showBackButton =
    router.pathname !== "/sumaversity" ||
    (!userProfile?.has_initial_questions && router.pathname === "/sumaversity");

  return (
    <div className={`absolute left-0 top-0 z-50 mt-3 w-full`}>
      <div className="mx-auto flex max-w-screen-xl items-center justify-between p-2 md:p-4 lg:p-4">
        <div className="flex w-full items-center sm:justify-start">
          {showBackButton && (
            <div className="h-10 w-10 cursor-pointer md:h-[3rem] md:w-[3rem] ">
              <FontAwesomeIcon
                onClick={backHandler}
                icon={faChevronLeft}
                className="m-2 ml-4 text-sumawealth-neutral-0 md:m-4 md:ml-0"
                size="lg"
              />
            </div>
          )}
        </div>
        <div className="flex w-full justify-center md:order-1 lg:order-1">
          <HeaderTextLink href="/sumaversity">
            <div
              className="flex w-20 select-none items-center md:w-[7em]"
              onClick={useNavbarDashboardTrack}
            >
              <Image priority src={logo} alt="Suma logo" />
            </div>
            <span className="text-suma-white"></span>
          </HeaderTextLink>
        </div>
        <div className="flex w-full justify-end gap-2 md:order-2 lg:order-2 lg:justify-center">
          {/* {selectButtonActionComponent} */}
          {/* {userProfile?.has_initial_questions && ()} */}
          <div className="relative">
            <div
              className={`flex h-10 w-10 items-center justify-center rounded-full p-2 ${isNotification ? "bg-sumawealth-warning-60" : "bg-sumawealth-neutral-0"}`}
              onClick={handleGoNotification}
            >
              <FontAwesomeIcon
                icon={faBell}
                size="lg"
                className="cursor-pointer self-center text-sumawealth-blue-100"
              />
            </div>
            {/** numero de notificaciones */}
            <div className="absolute right-0 top-0 flex h-[12px] w-[12px] -translate-x-2 translate-y-2 transform items-center justify-center rounded-full bg-red-500 text-xs text-white">
              <Typography
                variant="body"
                className="!text-[10px] text-sumawealth-neutral-0"
              >
                {numberNotification === 0
                  ? numberNotificationAPi
                  : numberNotification}
              </Typography>
            </div>
          </div>
          <div
            className="mr-4 flex h-10 w-14 items-center justify-center"
            onClick={onClickHomePage}
          >
            {/* <FontAwesomeIcon
              icon={faHome}
              size="lg"
              className="cursor-pointer self-center text-sumawealth-blue-100"
            /> */}
            <LanguageSelector type="header" visible={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SumaversiryHeader;
