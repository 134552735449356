import React from 'react';
import { Header } from './Header';
import Image from "next/legacy/image";
import logo from '@/images/logo-suma-azul.webp';
import { HeaderTextLink } from './HeaderTextLink';
import LanguageSelector from '@/components/molecules/LanguageSelector';

interface LoggedOutHeaderProps {}

export const LoggedOutHeader: React.FC<LoggedOutHeaderProps> = () => {
  return (
    <Header>
      <div className="fixed z-50 w-full">
        <div className="mx-auto flex max-w-screen-xl items-center justify-between p-2 md:p-4 lg:p-4">
          <div className="flex w-full items-center sm:justify-start"></div>
          <div className="flex w-full justify-center md:order-1 lg:order-1">
            <HeaderTextLink href="/sumaversity">
              <div className="flex w-20 select-none items-center md:w-[7em]">
                <Image priority src={logo} alt="Suma logo" />
              </div>
            </HeaderTextLink>
          </div>
          <div className="flex w-full justify-end md:order-2 lg:order-2 lg:justify-center"></div>
          <LanguageSelector type="header" visible={true} />
        </div>
      </div>
    </Header>
  );
};
